import React, { useContext } from "react";
import { ColorContext } from "./Context";

function Header({ myStyle }) {
  const [, setTheme] = useContext(ColorContext);
  function changeColor({ target }) {
    setTheme(target.value);
  }

  return (
    <header className="header" style={{ backgroundColor: myStyle }}>
      <h2>Memoer</h2>
      <div className="colors">
        <label>Themes:</label>
        <select onChange={changeColor}>
          <option>default</option>
          <option>Black</option>
        </select>
      </div>
    </header>
  );
}

export default Header;
