import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LevelContext, CategoryContext } from "./Context";
import { availableLevels } from "./AvailableLevels";

export default function Homepage({ myStyle }) {
  const [, setLevel] = useContext(LevelContext);
  const [, setCategory] = useContext(CategoryContext);

  function saveLevel({ target }) {
    setLevel(target.value);
  }

  function saveCategory({ target }) {
    setCategory(target.value);
  }

  return (
    <main className="main">
      <h3>How good is your memory?</h3>
      <h4>
        Check it out by playing <span>Memoer.</span>
      </h4>
      <label htmlFor="category" style={{ backgroundColor: myStyle }}>
        Choose Category
      </label>
      <select
        id="category"
        onChange={saveCategory}
        style={{ border: `1px solid ${myStyle}` }}
      >
        <option>Brands</option>
        <option>Alphabets</option>
        <option>Shapes</option>
        <option>Numbers</option>
      </select>
      <label htmlFor="level" style={{ backgroundColor: myStyle }}>
        Choose Level
      </label>
      <select
        id="level"
        onChange={saveLevel}
        style={{ border: `1px solid ${myStyle}` }}
      >
        {availableLevels.map((level) => {
          return <option key={level}>{level}</option>;
        })}
      </select>
      <Link
        className="homepage--link"
        to="/game-page"
        style={{ color: myStyle, border: `2px solid ${myStyle}` }}
      >
        Start
      </Link>
      <img src="../Ellipse 1.png" className="elipse1" alt="#" />
      <img src="../Ellipse 2.png" className="elipse2" alt="#" />
    </main>
  );
}
