import React from "react";

function Card({ icon, cards, card, revealCard, category, myStyle }) {
  let containerStyle;
  let iconSize;
  if (cards.length >= 24) {
    containerStyle = {
      height: "40px",
    };
    iconSize = {
      fontSize: "1.5rem",
    };
  }
  return (
    <div className="card--container" style={containerStyle}>
      <div
        className={card.isFlipped ? "card reveal" : "card"}
        onClick={revealCard}
      >
        <div className="card--back" style={{ backgroundColor: myStyle }}>
          Tap
        </div>
        <div className="card--front" style={{ border: `1px solid ${myStyle}` }}>
          {category !== "Numbers" && (
            <i style={{ iconSize, color: myStyle }} className={icon}></i>
          )}
          {category === "Numbers" && (
            <h3 style={{ color: myStyle }}>{card.key}</h3>
          )}
        </div>
      </div>
    </div>
  );
}

export default Card;
