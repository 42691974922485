import { availableLevels } from "./AvailableLevels";
let performance = [];
for (let i = 1; i <= availableLevels.length; i++) {
  performance.push({ level: `Level ${i}`, BestMoves: null });
}
const performanceRecord = {
  Brands: performance,
  Alphabets: performance,
  Shapes: performance,
  Numbers: performance,
};

export default performanceRecord;
