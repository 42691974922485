import React from "react";

function Footer({ myStyle }) {
  return (
    <footer className="footer" style={{ backgroundColor: myStyle }}>
      <h4>Memoer</h4>
      <small>&#169; Kinplus Technologies.</small>
      <div className="footer--social">
        <a
          href="https://www.facebook.com/kinplustechnologies"
          target="_blank"
          title="Kinplus Technologies Facebook Page"
        >
          <i className="fa-brands fa-facebook"></i>
        </a>
        <a
          href="https://twitter.com/kinplus_t"
          target="_blank"
          title="Kinplus Technologies Twitter Account"
        >
          <i className="fa-brands fa-twitter"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/kinplustechnologies"
          target="_blank"
          title="Kinplus Technologies LinkedIn Company Page"
        >
          <i className="fa-brands fa-linkedin"></i>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
